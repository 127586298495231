import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
export default function OfferBanner() {
  return (
    <div className="flags-div">
      <div className="flagdiv blueflag"></div>
      <div className="flagdiv whiteflag">
        <Link to={'/offer'}>
          {' '}
          <FormattedMessage
            id={'offer.discover'}
            defaultMessage={'Discover our Offers'}
          />
        </Link>
      </div>
      <div className="flagdiv redflag"></div>
    </div>
  );
}
