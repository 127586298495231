import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Link } from 'react-router-dom';
import { AuthContext } from '../App';
import { API_ROUTES } from '../data/constants';
import sendKeyEvent from '../components/googleanalytics/keyeventscomponent';

export default function CheckoutComplete() {
  const {
    isLoggedIn,
    setProducts,
    setAppliedPromoCode,
    setPromoCode,
    setConfirmBasketProducts,
    performanceCookies,
  } = useContext(AuthContext);
  useEffect(() => {
    if (performanceCookies) {
      sendKeyEvent({
        eventCategory: 'E-Commerce',
        eventAction: 'Checkout-Complete',
      });
    }
  }, [performanceCookies]);
  useEffect(() => {
    if (!isLoggedIn) {
      localStorage.removeItem('products');
      setAppliedPromoCode(false);
      setPromoCode({});
      // FetchData(setProducts);
      setConfirmBasketProducts({});
    }
  }, [isLoggedIn]);
  return (
    <React.Fragment>
      <h1>
        <FormattedMessage
          id="checkoutComplete.title"
          defaultMessage="Checkout completed - thank you for your order"
        />
      </h1>
      <p className="checkout-okay">
        <FormattedMessage
          id="checkoutComplete.text"
          defaultMessage={'We have sent you an email with a detailed summary.'}
        />
      </p>
      {isLoggedIn ? (
        <Link to="/account/Orders">
          <FormattedMessage
            id="checkoutComplete.link"
            defaultMessage="See orders."
          />
        </Link>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}
