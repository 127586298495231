import React, { useState, useEffect } from 'react';

/**
 * A simple carousel that displays each child in full-width slides,
 * allows the user to navigate with next/prev arrows, and auto-plays
 * every 3 seconds.
 */
export default function ProductsCarousel({ children }) {
  // Start at index 1 for your chosen initial offset
  const [currentIndex, setCurrentIndex] = useState(1);
  const totalSlides = React.Children.count(children);

  const handlePrev = () => {
    // If we’re at index 1, wrap around to the last slide
    if (currentIndex === 1) {
      setCurrentIndex(totalSlides - 1);
    } else {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  const handleNext = () => {
    // If we’re at the last slide, wrap around to index 1
    if (currentIndex === totalSlides - 1) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, totalSlides - 1));
    }
  };

  // Auto-play effect: go to next slide every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        // Mirror the handleNext logic in a callback
        if (prevIndex === totalSlides - 1) {
          return 1;
        }
        return Math.min(prevIndex + 1, totalSlides - 1);
      });
    }, 3000);

    // Clean up on unmount
    return () => clearInterval(intervalId);
  }, [totalSlides]);

  return (
    <div className="products-carousel-container">
      {totalSlides > 1 && (
        <button
          className="carousel-button carousel-button-prev"
          onClick={handlePrev}
        >
          &lt;
        </button>
      )}
      <div
        className="carousel-inner"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {React.Children.map(children, (child) => (
          <div className="carousel-item">{child}</div>
        ))}
      </div>
      {totalSlides > 1 && (
        <button
          className="carousel-button carousel-button-next"
          onClick={handleNext}
        >
          &gt;
        </button>
      )}
    </div>
  );
}
