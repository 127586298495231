import { FormattedMessage } from 'react-intl';
import React, { useContext } from 'react';
import AddressComponent from '../components/basketcomponents/addresscomponent';
import { AuthContext } from '../App';
import LoginForm from '../components/login/logincomponent';
import CustomHelmet from '../components/helmet/helmet';
import { Link } from 'react-router-dom';
import OrderStepsComponent from '../components/ordersteps/orderstepscomponent';

export default function AddressPage() {
  const { socket, user } = useContext(AuthContext);
  // console.log(user);
  return (
    <React.Fragment>
      <CustomHelmet />
      <OrderStepsComponent stepNumber={0} />
      {socket ? (
        <h1>
          <FormattedMessage
            id="addresses.title"
            defaultMessage="Pick or add an address"
          />
        </h1>
      ) : (
        <React.Fragment>
          <h1>
            <FormattedMessage
              id="addresses.title-notlogged"
              defaultMessage="Pick or add an address"
            />
          </h1>
          <Link to={'/login'}>
            <p>
              {' '}
              <FormattedMessage
                id="addresses.signupplease"
                defaultMessage="Save time on your next purchase ! "
              />
              <button className="login-button">
                <FormattedMessage id="loginform.signup" />
              </button>
            </p>
            <p>
              {' '}
              <FormattedMessage
                id="addresses.loginplease"
                defaultMessage="Already have an account ? "
              />{' '}
              <button className="login-button">
                <FormattedMessage id="loginform.login" />
              </button>
            </p>
          </Link>
        </React.Fragment>
      )}
      <AddressComponent addressType="shipping" />
    </React.Fragment>
  );
  // ) : socket ? (
  //   <div className="content-div">
  //     <h1>
  //       <FormattedMessage
  //         id="addresses.verifyemail"
  //         defaultMessage="A Verified email is required."
  //       />
  //     </h1>

  //     <p>
  //       <Link to="/account/General">
  //         <FormattedMessage
  //           id="addresses.accountmanagement"
  //           defaultMessage="Go to Account Management Page."
  //         />{' '}
  //       </Link>
  //     </p>
  //   </div>
  // ) : (
  //   <div className="content-div">
  //     <h1>
  //       <FormattedMessage
  //         id="basketpage.loginprompt"
  //         defaultMessage="Log in to place an order!"
  //       />
  //     </h1>
  //     <LoginForm />
  //   </div>
  // );
}
