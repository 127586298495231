import logo from '../../assets/logo.webp';
import { Link } from 'react-router-dom';
import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from '../../App';
import CreateProductPreviewCard from '../productcardscomponent/productcardscomponent';
import Carousel from '../productcomponents/productcarousel';
import ProductsCarousel from '../common/ProductsCarousel';
import fb_logo from '../../assets/fb_logo.png';
import insta_logo from '../../assets/insta_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
export default function OfferPageComponent() {
  const { currentOffer, products, locale, activeOffer, setActiveOffer } =
    useContext(AuthContext);

  const [offerProducts, setOfferProducts] = useState([]);

  useEffect(() => {
    // Vérifier que currentOffer existe et qu'il n'est pas vide
    if (
      currentOffer &&
      Object.keys(currentOffer).length > 0 &&
      products.length > 0
    ) {
      setActiveOffer(true);
      const filteredProducts = products.filter((product) =>
        currentOffer.linked_products.some(
          (linked_product) => product._id === linked_product.product_id
        )
      );
      setOfferProducts(filteredProducts);
      console.log(currentOffer);
    } else {
      setActiveOffer(false);
      console.log(currentOffer);
    }
  }, [currentOffer, products]);
  if (activeOffer) {
    // console.log(currentOffer.background_color);
    const languageNameKey = `title_${locale}`;
    const languageDescKey = `desc_${locale}`;
    const offerName = currentOffer[languageNameKey];
    const offerDesc = currentOffer[languageDescKey];
    return activeOffer ? (
      <div className="current-offer-div">
        <div className="offer-presentation">
          <h2>{offerName}</h2>
          <p>{offerDesc}</p>
          <a
            href={currentOffer.fb_link}
            target="_blank"
            className="social_link"
          >
            <p>
              <FormattedMessage
                id="offer.facebook"
                defaultMessage="See on Facebook!"
              />
            </p>
            <img
              className="social_logo"
              src={fb_logo}
            />
          </a>{' '}
          <a
            href={currentOffer.insta_link}
            target="_blank"
            className="social_link"
          >
            <p>
              <FormattedMessage
                id="offer.instagram"
                defaultMessage="See on Instagram!"
              />
            </p>
            <img
              className="social_logo"
              src={insta_logo}
            />
          </a>
        </div>
        <ProductsCarousel>
          {' '}
          {offerProducts.length > 0
            ? offerProducts.map((product, index) => (
                <CreateProductPreviewCard
                  key={product._id}
                  {...product}
                />
              ))
            : ''}
        </ProductsCarousel>
      </div>
    ) : (
      ''
    );
  } else return;
}
