import { FormattedMessage } from 'react-intl';

// import ShippingComponent from '../components/basketcomponents/shippingcomponent';
import VerifyEmailComponent from '../components/verifyemailcomponent/verifyemailcomponent';
import React from 'react';

export default function VerifyEmailPage() {
  return (
    <React.Fragment>
      <h1>
        {' '}
        <FormattedMessage
          id="emailverification.title"
          defaultMessage="Email Verification"
        />
      </h1>
      <VerifyEmailComponent />
    </React.Fragment>
  );
}
