import { FormattedMessage } from 'react-intl';

import ShippingComponent from '../components/basketcomponents/shippingcomponent';
import LoginForm from '../components/login/logincomponent';
import React, { useContext } from 'react';
import { AuthContext } from '../App';
import OrderStepsComponent from '../components/ordersteps/orderstepscomponent';
export default function ShippingPage() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <React.Fragment>
      <OrderStepsComponent stepNumber={1} />
      <h1>
        {' '}
        <FormattedMessage
          id="shipping.title"
          defaultMessage="Shipping Methods"
        />
      </h1>
      <ShippingComponent />
    </React.Fragment>
  );
}
