import logo from '../../assets/logo.webp';
import { Link } from 'react-router-dom';
import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from '../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faBasketShopping,
  faCircleUser,
  faHome,
  faMailBulk,
  faRightFromBracket,
  faShop,
  faUser,
  faUserFriends,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import ukFlag from '../../assets/uk-flag.svg';
import frFlag from '../../assets/fr-flag.svg';

import { act } from 'react';

export default function Banner() {
  const {
    setIsLoggedIn,
    locale,
    setLocale,
    isLoggedIn,
    confirmBasketProducts,
    socket,
    cartIconRef,
  } = useContext(AuthContext);

  const [basketQuantities, setBasketQuantities] = useState(0);
  const [collapsed, setCollapsed] = useState(true);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const localProducts = JSON.parse(localStorage.getItem('products'));
    if (localProducts !== null) {
      const totalQuantity = localProducts.reduce(
        (acc, product) => acc + product.productQuantity,
        0
      );
      setBasketQuantities(totalQuantity);
    } else {
      setBasketQuantities(0);
    }
  }, [confirmBasketProducts]);

  useEffect(() => {
    if (isLoggedIn && socket) {
      socket.emit('update-user', { locale: locale });
    }
  }, [locale, isLoggedIn, socket]);

  const switchLocale = (newLocale) => {
    setLocale(newLocale);
  };

  // Helper function to generate localized paths
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };

  return (
    <header className={`header `}>
      {/* Desktop Navigation */}
      <nav className="nav desktop-nav">
        <ul className="navList left-nav">
          {/* Basket Link */}
          <li className="navLink">
            <Link
              to={getLocalizedPath('/basket')}
              ref={cartIconRef}
            >
              <span>
                <FontAwesomeIcon icon={faBasketShopping} />
              </span>
              {basketQuantities > 0 && (
                <span className="basket-banner-quantity">
                  {basketQuantities}
                </span>
              )}
            </Link>
          </li>
          {/* Home Link */}
          <li className="navLink">
            <Link to={getLocalizedPath('/')}>
              <FormattedMessage
                id="banner.home"
                defaultMessage="Home"
              />
            </Link>
          </li>
          {/* Producers Link */}
          <li className="navLink">
            <Link to={getLocalizedPath('/producers')}>
              <FormattedMessage
                id="banner.producers"
                defaultMessage="Producers"
              />
            </Link>
          </li>
        </ul>
        {/* Logo */}
        <div className="bannerLogoDiv">
          <Link
            to={getLocalizedPath('/')}
            onClick={() => setCollapsed(true)}
          >
            <img
              className="bannerLogo logo"
              src={logo}
              alt="logo de Riviera"
            />
          </Link>
        </div>

        <ul className="nav desktop-nav right-nav">
          <li className="navLink">
            <Link
              to={getLocalizedPath('/team')}
              onClick={() => setCollapsed(true)}
            >
              <FormattedMessage
                id="banner.team"
                defaultMessage="Meet the Team"
              />{' '}
            </Link>
          </li>
          {/* Products Link */}
          <li className="navLink">
            <Link to={getLocalizedPath('/gallery')}>
              <FormattedMessage
                id="banner.products"
                defaultMessage="Products"
              />
            </Link>
          </li>
          {/* Account Links */}
          {isLoggedIn ? (
            <>
              <li className="navLink">
                <Link to={getLocalizedPath('/account/General')}>
                  <FontAwesomeIcon icon={faCircleUser} />
                </Link>
              </li>
              <li
                onClick={handleLogout}
                className="navLink"
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
              </li>
            </>
          ) : (
            <li className="navLink">
              <Link to={getLocalizedPath('/login')}>
                <FontAwesomeIcon icon={faCircleUser} />
              </Link>
            </li>
          )}
          {/* Language Switcher */}
          <li className="navLink bannerLang">
            <Link
              onClick={() => switchLocale('en')}
              style={{ cursor: 'pointer' }}
              className="language-flag"
              to="/en"
            >
              <img
                src={ukFlag}
                alt="UK Flag"
              />
            </Link>
          </li>
          <li className="navLink bannerLang">
            <Link
              onClick={() => switchLocale('fr')}
              style={{ cursor: 'pointer' }}
              className="language-flag"
              to="/fr"
            >
              <img
                src={frFlag}
                alt="FR Flag"
              />
            </Link>
          </li>
        </ul>
      </nav>

      {/* Mobile Navigation */}
      <nav className="nav mobile-nav">
        <div className="mobile-nav-element">
          {/* Mobile Basket Link */}
          <span className="mobile-nav-basket">
            <Link
              to={getLocalizedPath('/basket')}
              onClick={() => setCollapsed(true)}
              ref={cartIconRef}
            >
              <FontAwesomeIcon icon={faBasketShopping} />
              {basketQuantities > 0 && (
                <span className="basket-banner-quantity">
                  {basketQuantities}
                </span>
              )}
            </Link>
          </span>
        </div>
        {/* Mobile Logo */}
        <div className="bannerLogoDiv mobile-nav-element">
          <Link
            to={getLocalizedPath('/')}
            onClick={() => setCollapsed(true)}
          >
            <img
              className="bannerLogo logo"
              src={logo}
              alt="logo de Riviera"
            />
          </Link>
        </div>
        {/* Mobile Menu Toggle */}
        <div className="mobile-nav-element">
          <button onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faX} />
            )}
          </button>
          <div className={`collapsible-nav ${collapsed ? 'collapsed' : ''}`}>
            <ul className="navList mobile-nav-list">
              {/* Mobile Home Link */}
              <li className="navLink">
                <Link
                  to={getLocalizedPath('/')}
                  onClick={() => setCollapsed(true)}
                >
                  <span>
                    <FormattedMessage
                      id="banner.home"
                      defaultMessage="Home"
                    />{' '}
                    <FontAwesomeIcon icon={faHome} />
                  </span>
                </Link>
              </li>
              {/* Mobile Products Link */}
              <li className="navLink">
                <Link
                  to={getLocalizedPath('/gallery')}
                  onClick={() => setCollapsed(true)}
                >
                  <span>
                    <FormattedMessage
                      id="banner.products"
                      defaultMessage="Products"
                    />{' '}
                    <FontAwesomeIcon icon={faShop} />
                  </span>
                </Link>
              </li>
              {/* Mobile Producers Link */}
              <li className="navLink">
                <Link
                  to={getLocalizedPath('/producers')}
                  onClick={() => setCollapsed(true)}
                >
                  <span>
                    <FormattedMessage
                      id="banner.producers"
                      defaultMessage="Producers"
                    />{' '}
                    <FontAwesomeIcon icon={faUserFriends} />
                  </span>
                </Link>
              </li>
              {/* Mobile Team Link */}
              <li className="navLink">
                <Link
                  to={getLocalizedPath('/team')}
                  onClick={() => setCollapsed(true)}
                >
                  <span>
                    <FormattedMessage
                      id="banner.team"
                      defaultMessage="Meet the Team"
                    />{' '}
                    <img
                      src={logo}
                      className="menu-icon"
                      alt="Team"
                    />
                  </span>
                </Link>
              </li>
              {/* Mobile Contact Link */}
              <li className="navLink">
                <Link
                  to={getLocalizedPath('/contact')}
                  onClick={() => setCollapsed(true)}
                >
                  <span>
                    <FormattedMessage
                      id="footer.contact"
                      defaultMessage="Contact us!"
                    />{' '}
                    <FontAwesomeIcon icon={faMailBulk} />
                  </span>
                </Link>
              </li>
              {/* Mobile Account Links */}
              {isLoggedIn ? (
                <>
                  <li className="navLink">
                    <Link
                      to={getLocalizedPath('/account/General')}
                      onClick={() => setCollapsed(true)}
                    >
                      <span>
                        <FormattedMessage
                          id="navlink.account"
                          defaultMessage="My Account"
                        />{' '}
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </Link>
                  </li>
                  <li
                    className="navLink"
                    onClick={() => {
                      handleLogout();
                      setCollapsed(true);
                    }}
                  >
                    <span>
                      <FormattedMessage
                        id="navlink.logout"
                        defaultMessage="Log Out"
                      />{' '}
                      <FontAwesomeIcon icon={faRightFromBracket} />
                    </span>
                  </li>
                </>
              ) : (
                <li className="navLink">
                  <Link
                    to={getLocalizedPath('/login')}
                    onClick={() => setCollapsed(true)}
                  >
                    <span>
                      <FormattedMessage
                        id="navlink.login"
                        defaultMessage="Log In / Sign Up"
                      />{' '}
                      <FontAwesomeIcon icon={faCircleUser} />
                    </span>
                  </Link>
                </li>
              )}
            </ul>

            {/* Mobile Language Switcher */}
            <div className="bannerLang">
              <Link
                onClick={() => {
                  switchLocale('en');
                  setCollapsed(true);
                }}
                style={{ cursor: 'pointer' }}
                className="language-flag"
                to="/en"
              >
                <img
                  src={ukFlag}
                  alt="UK Flag"
                />
              </Link>
              <Link
                onClick={() => {
                  switchLocale('fr');
                  setCollapsed(true);
                }}
                style={{ cursor: 'pointer' }}
                className="language-flag"
                to="/fr"
              >
                <img
                  src={frFlag}
                  alt="FR Flag"
                />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
