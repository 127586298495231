import { FormattedMessage } from 'react-intl';
import ScrollToTop from '../components/common/scrolltotop';
import ProducersGallery from '../components/producerscomponent/produceritems';
import React from 'react';

export default function ProducersPage() {
  ScrollToTop();
  return (
    <React.Fragment>
      <h1>
        <FormattedMessage
          id={'producers.title'}
          defaultMessage={'Producers'}
        />
      </h1>
      <ProducersGallery />
    </React.Fragment>
  );
}
